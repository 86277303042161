<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-5 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <h1 class="flex my-4" color="#f9c99e">
                    Join Arena
                  </h1>
                </div>
                <v-form lazy-validation>
                  <v-text-field
                    label="Token"
                    color="grey darken-4"
                    name="token"
                    prepend-icon="mdi mdi-alpha-t-circle"
                    type="text"
                    required
                    v-model="form.token"
                    :error="error"
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Password"
                    color="grey darken-4"
                    name="password"
                    prepend-icon="mdi mdi-lock"
                    required
                    v-model="form.password"
                    :rules="[rules.required]"
                    :error="error"
                    @click:append="hidePassword = !hidePassword"
                    v-on:keyup="validateKey"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  block
                  color="grey darken-4"
                  dark
                  @click="login()"
                  :loading="loading"
                  >Join</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-snackbar
        v-model="snackbar"
        :color="color"
        :multi-line="true"
        :timeout="3000"
      >
        {{ text }}
        <v-btn dark text @click="snackbar = false">Tutup</v-btn>
      </v-snackbar>
    </v-main>
    <v-footer padless fixed>
      <v-col class="text-center">
        {{ new Date().getFullYear() }} —
        <!-- <a href="https://github.com/niusindra" target="_blank"
          >github.com/niusindra</a -->
          <strong>Pengurus Pusat Hapkido Indonesia</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>


<script>
  // Add the Firebase products that you want to use
  import * as firebase from 'firebase/app'
  import 'firebase/database'

  export default {
    data() {
      return {
        loading: false,
        load: false,
        snackbar: false,
        hidePassword: true,
        error: false,
        color: null,
        text: "",
        rules: {
          required: (value) => !!value || "Required.",
        },
        form: {
          token: null,
          password: null,
        },
        // user: new FormData(),
        pegawai: [],
        typeInput: "new",
        errors: "",
      };
    },
    props: {
      source: String,
    },

    beforeCreate: function() {
        if (!this.$session.exists()) {
        this.$router.push({ name: "Login" });
        }
    },

    methods: {
      login() {
            var pass, token, status;
            token = this.form.token;
            pass = this.form.password;
            status = "";
            
            firebase.database().ref('Groups/')
            .once('value', function(snapshot){
                if (snapshot.exists()) {
                    snapshot.forEach(function(child) {
                    if(token == child.val().token && pass == child.val().password){
                        status = child.key;
                        console.log(child.key+": token->"+child.val().token +": password->"+child.val().password);
                        console.log(token + pass);
                        console.log("Berhasil masuk arena!");
                        alert('Enter Into The Arena Successfully');
                        
                    }
                });
                }else {
                    console.log("No data available");
                }
            }).then(()=>{
                if(status != ""){
                    this.$session.set("arenaUid", status);
                    this.$router.push('/score');
                }
            })
            .catch(function(error) {
                console.error(error);
            });     
    },
      validateKey(e) {
        if (e.keyCode === 13) {
          this.login();
        }
      },
    },
  };
</script>
